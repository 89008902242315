@import '../helpers/mixins';
@import '../helpers/variables';
@import '../helpers/rithm-light-palettes';

// global styling for circle elements.

/// Used chip filter.
/// 18px circle in our secondary color.
.circle-18-secondary {
  @apply rounded-full h-[18px] w-[18px] text-center text-[8px] font-semibold leading-5 text-white bg-secondary-500;
}

/// Used chip filter.
/// 20px circle in our secondary color.
.circle-20-secondary {
  @apply rounded-full h-6 w-6 text-center text-[8px] font-semibold leading-6 text-white bg-secondary-500;
}

/// Used on Containers, the Containers modal, and Notifications.
/// 30px circle in our secondary color.
.circle-30-secondary {
  @apply rounded-full h-7 w-7 text-center text-xs font-semibold leading-[26px] text-white bg-secondary-500 hover:bg-secondary-700 border border-secondary-50;
}

/// Used on Containers and containers modal.
/// 32px circle in our secondary color.
.circle-32-secondary {
  @apply rounded-full h-[32px] w-[32px] text-center text-xs font-semibold leading-[26px] text-white bg-secondary-500 hover:bg-secondary-700 border border-secondary-50;
}

/// Used on Worker Roster.
/// 48px circle in our secondary color.
.circle-48-secondary-200 {
  @apply rounded-full h-12 w-12 text-center text-xs font-semibold leading-[26px] text-black bg-secondary-200 hover:bg-secondary-200 border border-secondary-50;
}

/// Used on Worker Roster.
/// 36px circle in our secondary color.
.circle-36-secondary-200 {
  @apply rounded-full h-9 w-9 text-center text-xs font-semibold leading-[26px] text-black bg-secondary-200 hover:bg-secondary-200 border border-secondary-50;
}

/// Used on Worker Roster.
/// 30px circle in our secondary color.
.circle-30-secondary-200 {
  @apply rounded-full h-[30px] w-[30px] text-center text-xs font-semibold leading-[26px] text-black bg-secondary-200 hover:bg-secondary-200 border border-secondary-50;
}

/// Used on Priority.
/// 30px circle in our accent color.
.circle-30-accent {
  @apply rounded-full h-7 w-7 text-center text-sm font-semibold leading-7 text-white bg-accent-500;
}

/// Used as the User Avatar and on the Worker Roster Modal
/// 36px circle in our secondary color.
.circle-36-secondary {
  @apply rounded-full h-9 w-9 text-center text-base font-semibold leading-9 text-white bg-secondary-500 border border-secondary-50 hover:bg-secondary-700;
}

/// Used as the User Avatar and on the Worker Roster Modal
/// 36px circle in our accent color.
.circle-36-accent {
  @apply rounded-full h-9 w-9 text-center text-base font-semibold leading-9 text-white bg-accent-500 border border-accent-50 hover:bg-accent-700;
}

/// Used as the User Avatar and on the Worker Roster Modal
/// 40px circle in our secondary color.
.circle-40-secondary {
  @apply rounded-full h-10 w-10 text-center text-base font-semibold leading-10 text-white bg-secondary-500 border border-secondary-200 hover:bg-secondary-700;
}

/// Used for assign user.
/// 48px circle in our secondary color.
.circle-48-secondary {
  @apply rounded-full h-12 w-12 text-center text-base font-semibold leading-10 text-white bg-secondary-500 border border-secondary-200 hover:bg-secondary-700;
}

/// Used as the User Avatar
/// 80px circle in our secondary color.
.circle-80-secondary {
  @apply rounded-full h-[80px] w-[80px] flex justify-center items-center text-base font-semibold leading-10 text-white bg-secondary-500 border border-secondary-200 hover:bg-secondary-700;
}

/// Used as the User Avatar
/// 72px circle in our secondary color.
.circle-72-accent {
  @apply rounded-full h-[72px] w-[72px] flex justify-center items-center text-xl font-semibold text-white bg-accent-500 border border-accent-200 hover:bg-accent-700;
}

/// Used as the User Avatar
/// 90px circle in our secondary color.
.circle-90-accent {
  @apply rounded-full h-[90px] w-[90px] flex justify-center items-center text-xl font-semibold text-white bg-accent-500 border border-accent-200 hover:bg-accent-700;
}

/// Used as the Rithm Avatar and on the History Drawer
/// 40px circle in our primary color.
.circle-40-primary {
  @apply rounded-full h-10 w-10 text-center text-base font-semibold leading-10 text-white bg-primary-500 border border-primary-200 hover:bg-primary-700;
}

/// Used as tab user in station drawer.
/// 48px circle in our primary color.
.circle-48-primary {
  @apply rounded-full h-12 w-12 text-center text-base font-semibold leading-10 text-white bg-primary-500 border border-primary-200 hover:bg-primary-700;
}

/// Badges in the accent color, commonly used on the user avatar/profile icon.
.badge-accent {
  @apply rounded-full h-4 w-4 text-center leading-4 text-white bg-accent-500;

  font-size: fixed-font-size(8);
}
