@import '../helpers/mixins';
@import '../helpers/variables';
@import '../helpers/rithm-light-palettes';

.drag-and-drop-zone {
  @apply w-full flex items-center justify-center rounded-lg border-dashed border-2 border-secondary-200;

  &.file-dragover {
    @apply bg-secondary-100 border-secondary-300 cursor-pointer;
  }
}
