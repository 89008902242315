@import '../helpers/mixins';
@import '../helpers/variables';
@import '../helpers/rithm-light-palettes';

// global styling for square elements.

/// used for avatar in format square
/// 20px square in our secondary color.
.square-20-secondary {
  @apply rounded-lg w-6 h-6 text-center text-[8px] font-semibold leading-6 text-white bg-secondary-500;
}

/// used for avatar in format square
/// 36px square in our secondary color.
.square-36-secondary {
  @apply rounded-lg h-9 w-9 text-center text-base font-semibold leading-9 text-white bg-secondary-500 border border-secondary-50 hover:bg-secondary-700;
}

/// used for avatar in format square
/// 40px square in our secondary color.
.square-40-secondary {
  @apply rounded-lg h-10 w-10 text-center text-base font-semibold leading-10 text-white bg-secondary-500 border border-secondary-200 hover:bg-secondary-700;
}

/// used for avatar in format square
/// 48px square in our secondary color.
.square-48-secondary {
  @apply rounded-lg h-12 w-12 text-center text-base font-semibold leading-10 text-white bg-secondary-500 border border-secondary-200 hover:bg-secondary-700;
}

/// used for avatar in format square
/// 90px square in our secondary color.
.square-90-secondary {
  @apply rounded-lg h-[90px] w-[90px] flex justify-center items-center text-xl font-semibold text-white bg-secondary-500 border border-accent-200 hover:bg-secondary-700;
}
