/// Set global styles across the application
/* stylelint-disable */
@use '@angular/material' as mat;

@import '../helpers/functions';

$custom-font-family: 'Montserrat, Open Sans, Helvetica';

/// Rithm Typography Level: Display 4
$rithm-display-4: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 400,
  $font-size: font-calc(112),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Display 3
$rithm-display-3: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 400,
  $font-size: font-calc(56),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Display 2
$rithm-display-2: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 400,
  $font-size: font-calc(45),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Display 1
$rithm-display-1: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 400,
  $font-size: font-calc(34),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Headline
$rithm-headline: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 300,
  $font-size: font-calc(48),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Title
$rithm-title: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 300,
  $font-size: font-calc(34),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Subheading 2
$rithm-subheading-2: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 400,
  $font-size: font-calc(24),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Subheading 1
$rithm-subheading-1: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 400,
  $font-size: font-calc(20),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Body 1
$rithm-body-1: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 400,
  $font-size: font-calc(16),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Body 2
$rithm-body-2: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 500,
  $font-size: font-calc(16),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Caption
$rithm-caption: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 400,
  $font-size: font-calc(12),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Button
$rithm-button: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 500,
  $font-size: font-calc(14),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Rithm Typography Level: Input
$rithm-input: mat.m2-define-typography-level(
  $font-family: $custom-font-family,
  $font-weight: 400,
  $font-size: font-calc(16),
  $line-height: 1,
  $letter-spacing: normal,
);

/// Custom Typography Configuration
$custom-typography: mat.m2-define-typography-config(
  $font-family: $custom-font-family,
  $headline-1: $rithm-display-4,
  $headline-2: $rithm-display-3,
  $headline-3: $rithm-display-2,
  $headline-4: $rithm-display-1,
  $headline-5: $rithm-headline,
  $headline-6: $rithm-title,
  $subtitle-1: $rithm-subheading-2,
  $body-1: $rithm-subheading-1,
  $body-2: $rithm-body-1,
  $subtitle-2: $rithm-body-2,
  $caption: $rithm-caption,
  $button: $rithm-button,
  /* TODO(mdc-migration): No longer supported. Use `body-1` instead. $input: $rithm-input, */
);

/// Montserrat Thin 100
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

/// Montserrat Thin 100 Italic
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

/// Montserrat Extra Light 200
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
}

/// Montserrat Extra Light 200 Italic
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

/// Montserrat Light 300
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

/// Montserrat Light 300 Italic
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

/// Montserrat Regular 400
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

/// Montserrat Regular 400 Italic
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

/// Montserrat Medium 500
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

/// Montserrat Medium 500 italic
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

/// Montserrat Semi-Bold 600
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

/// Montserrat Semi-Bold 600 Italic
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

/// Montserrat Bold 700
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

/// Montserrat Bold 700 Italic
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

/// Montserrat Extra Bold 800
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

/// Montserrat Extra Bold 800 Italic
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

/// Montserrat Black 900
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

/// Montserrat Black 900 Italic
@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
