// Primary Colors---------------
@import './rithm-light-palettes';

/// Rithm Primary color 1 - Blue

/* stylelint-disable-next-line */
$primary-blue: map-get($primary-palette, 500);

// Accent Colors---------------

/// Rithm Accent Blue

/* stylelint-disable-next-line */
$accent-blue: map-get($accent-palette, 500);

// Secondary Colors---------------

/// Rithm Black
$secondary-black: #202020;

/// Rithm Secondary Blue
$secondary-blue: #2b3c54;

/// Rithm Secondary Gray

/* stylelint-disable-next-line */
$secondary-gray: map-get($secondary-gray-palette, 500);

// Sky Colors---------------
$sky-500: #0ea5e9;
$sky-300: #7dd3fc;

// Warning Colors---------------

/* stylelint-disable-next-line */
$warning-red: map-get($error-palette, 500);

// New Map
$new-map-color: #f9fafb;
$new-map-selected: #0284c7;
